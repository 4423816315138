<template>
    <div id="asset-model-form-wrapper-component">
        <v-switch v-model="model_configuration.is_required" :label="$t('global.activate')" inset />

        <v-expand-transition>
            <div v-if="model_configuration.is_required">
                <span class="font-14">{{ $t('asset_model_form_wrapper_component.select_container_type') }}</span>

                <v-row class="mb-4" dense>
                    <v-col v-for="asset_model in asset_module.asset_models" :key="asset_model.id_asset_model" cols="6">
                        <v-radio-group v-model="model_configuration.id_asset_model" class="mt-2" hide-details inline>
                            <div class="d-flex flex-column align-center">
                                <v-radio :value="asset_model.id_asset_model" class="ma-0 mb-1" />
                                <img
                                    :src="asset_model.path_model"
                                    alt="image asset"
                                    style="max-width: 70px; max-height: 50px"
                                    @error="setFallbackImage"
                                />
                                <span class="font-12">{{ asset_model.name }}</span>
                            </div>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <div v-if="model_configuration.id_asset_model">
                        <generic-form-component
                            :configuration="configuration"
                            :form_inputs="getModelById(model_configuration.id_asset_model).form_inputs"
                            v-on:update:configuration="handleConfigurationUpdate"
                        />

                        <v-select
                            v-model="model_configuration.id_asset_liquid"
                            :items="asset_liquids"
                            :label="$t('asset_model_form_wrapper_component.content')"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :rules="[(v) => !!v || $t('generic_form_component.required_fields')]"
                            filled
                            item-text="name"
                            item-value="id_asset_liquid"
                        />
                    </div>
                </v-expand-transition>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import GenericFormComponent from '@/components/installation-step/installation-step-4/generic/GenericFormComponent.vue'

export default {
    name: 'AssetModelFormWrapperComponent',
    components: { GenericFormComponent },
    data() {
        return {
            model_configuration: {
                is_required: this.configuration.is_required,
                id_asset_model: this.configuration.id_asset_model,
                id_asset_liquid: this.configuration.id_asset_liquid,
                configuration: {},
            },
        }
    },
    props: {
        asset_module: {
            type: Object,
            require: true,
        },
        //Configuration model brut from back
        configuration: {
            type: Object,
            require: true,
        },
        //Which column on the form
        column: {
            type: Number,
            require: true,
        },
        asset_liquids: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getModelById(id_asset_model) {
            return this.asset_module.asset_models.find((assetModel) => assetModel.id_asset_model === id_asset_model, 1)
        },
        setFallbackImage(event) {
            event.target.src = '/images/fallback_asset.png'
        },
        emitUpdate() {
            this.$emit('update:model', { model_configuration: this.model_configuration, column: this.column })
        },
        handleConfigurationUpdate(configuration) {
            this.model_configuration.configuration = configuration
            this.emitUpdate()
        },
    },
    watch: {
        model_configuration() {
            this.emitUpdate()
        },
    },
}
</script>

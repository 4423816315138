<template>
    <div id="installation-step-two" class="div-height">
        <v-card height="100%">
            <v-card-title>
                <v-icon class="pointer" @click="previousStep()">mdi-arrow-left</v-icon>
                <span class="ml-1">{{ $t('step_2.title') }}</span>
            </v-card-title>
            <v-card-text>
                <p>
                    <strong>{{ $t('step_2.inform_device_number') }}</strong>
                    <v-icon class="ml-1 pointer" color="primary" @click="dialog_help_device_number = true" v-if="$store.getters['global/is_bee_2_beep']">
                        mdi-information
                    </v-icon>
                </p>

                <v-form ref="form">
                    <v-text-field
                        v-model="device_number"
                        :label="$t('step_2.device_number')"
                        :rules="[v => !!v || $t('step_2.number_required')]"
                        class="rounded-0" outlined
                        :loading="loading_device_number"
                        append-icon="mdi-qrcode-scan"
                        @click:append="open_qrcode = true"
                        @keydown.enter.prevent="checkInstallationDevice()"
                    />

                    <v-checkbox
                        :label="$t('step_2.not_access_device_number')"
                        v-if="show_button_series_number"
                        v-model="show_input_series_number"
                        class="ma-0"
                    />


                    <div class="d-flex" v-if="show_input_series_number">
                        <v-text-field
                            v-model="device_series_number"
                            :label="$t('step_2.device_series_number')"
                            :rules="[v => !!v || $t('step_2.device_series_number')]"
                            class="rounded-0" outlined dense
                            :error-messages="show_error_message_series_number ? $t('step_2.device_series_number_required') : ''"
                            @keydown.enter.prevent="getDeviceNumberByDeviceSeriesNumber()"
                        />

                        <v-btn class="btn-device-series-number elevation-0 rounded-0" color="primary"
                               @click="getDeviceNumberByDeviceSeriesNumber()"
                        >
                            {{ $t('ok') }}
                        </v-btn>
                    </div>

                    <p v-if="show_information_message" class="font-italic">
                        {{ $t('step_2.message_associate_professional') }}
                    </p>

                    <v-row>
                        <v-col>
                            <v-btn block color="grey" large class="white--text" @click="previousStep()">
                                {{ $t('back') }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block color="primary" @click="checkInstallationDevice()" large>
                                {{ $t('next') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <scanner v-if="open_qrcode === true"
                 v-on:getDeviceNumber="getDeviceNumber"
                 v-on:closeQrCodeReader="closeQrCodeReader"
        />

        <v-dialog v-model="dialog_help_device_number" width="unset" v-if="$store.getters['global/is_bee_2_beep']">
            <v-card class="width-fit-content ma-auto">
                <v-card-text class="align-center pa-5 d-flex flex-column flex-md-row gap-5">
                    <v-img :src="img_capteur_1" max-height="300" max-width="300" contain/>
                    <v-img :src="img_capteur_2" max-height="300" max-width="300" contain/>
                    <v-img :src="img_capteur_3" max-height="300" max-width="300" contain/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import scanner from './scanner';
import img_capteur_1 from '/public/images/bee2beep_device/Capteur_GPS_BeeTrack.png'
import img_capteur_2 from '/public/images/bee2beep_device/Balance_connectee_pro.png'
import img_capteur_3 from '/public/images/bee2beep_device/balance_connectee_particulier.png'

export default {
    name: 'InstallationStepTwoComponent',
    components: {scanner},
    data() {
        return {
            loading_device_number: false,
            open_qrcode: false,
            device_number: '',

            show_button_series_number: false,
            show_input_series_number: false,
            device_series_number: '',
            show_error_message_series_number: false,

            dialog_help_device_number: false,
            img_capteur_1: img_capteur_1,
            img_capteur_2: img_capteur_2,
            img_capteur_3: img_capteur_3
        };
    },
    props: {
        device_number_scanned: {
            type: String,
            required: false
        },
    },
    watch: {
        device_number_scanned: function (value) {
            if (value !== undefined) {
                this.device_number = this.device_number_scanned;
            }
        },
    },
    computed: {
        show_information_message() {
            return this.$store.getters['global/without_user'] || !this.$store.getters['global/is_send_sign_up_mail'] ;
        }
    },
    methods: {
        getDeviceNumber(item) {
            this.device_number = item.device_number;
            this.open_qrcode = false;
        },

        closeQrCodeReader() {
            this.open_qrcode = false;
        },

        checkInstallationDevice() {
            if (this.$refs.form.validate()) {
                this.loading_device_number = true;
                this.error_snackbar = '';
                this.axios.get(
                    '/api/v1/device/' + this.device_number + '/is_installable'
                ).then((success) => {
                    if (success.data.data.is_installable === true) {
                        this.$emit('goToStepThree', {
                            device_number: this.device_number.trim(),
                            id_professional: success.data.data.id_professional,
                            name_professional: success.data.data.name_professional,
                            id_device: success.data.data.id_device,
                            id_device_referential: success.data.data.id_device_referential,
                            value_type_device: success.data.data.value_type_device,
                            url_device_detail: success.data.data.url_device_detail,
                            installation: {
                                notice: success.data.data.installation.notice,
                                description: success.data.data.installation.description,
                                introduction: success.data.data.installation.introduction
                            }
                        });
                    } else {
                        this.showSnackbar('warning', this.$t('step_2.device_cant_be_install'));
                    }
                }).catch((error) => {
                    let errorSnackbar = (error.response && error.response.status === 404) ? this.$t('step_2.device_not_found') : this.$t('step_2.error_get_device');
                    this.manageError('error', errorSnackbar, error);
                }).finally(() => {
                    this.loading_device_number = false;
                });
            }
        },

        previousStep() {
            this.$emit('previousStep');
        },

        getDeviceNumberByDeviceSeriesNumber() {
            this.show_error_message_series_number = false;

            if (this.device_series_number === "") {
                this.show_error_message_series_number = true;
            } else {
                this.axios.get(
                    '/api/v1/device/' + this.device_series_number + '/sigfox_number_by_serial_number'
                ).then((success) => {
                    this.device_number = success.data.data.sigfox_number;
                }).catch((error) => {
                    this.manageError('error', this.$t('step_2.device_series_number_not_found'), error);
                });
            }
        }
    },
    mounted() {
        if (window.location.host === 'installation.bee2beep.io' || window.location.host === 'installation.bee2beep.com') {
            this.show_button_series_number = true;
        }
    }
};
</script>

<style>
#installation-step-two {
    max-width: 1000px;
    margin: auto;
}

.btn-device-series-number {
    height: 40px !important;
    border-radius: 0 4px 4px 0;
}

.gap-5 {
    gap: 5px;
}

.width-fit-content {
    width: fit-content !important;
}
</style>